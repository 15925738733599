<template>
  <div class="register">
    <div class="title">修改会员密码</div>
    <a-form
      :model="formData"
      name="password"
      :label-col="{ span: 6 }"
      ref="passwordForm"
      :rules="rules"
      :wrapper-col="{ span: 14 }"
      autocomplete="off"
    >
      <a-form-item label="当前密码" name="oldPassword">
        <a-input-password v-model:value="formData.oldPassword" maxlength="18"/>
      </a-form-item>
      <a-form-item label="新密码" name="newPassword">
        <a-input-password v-model:value="formData.newPassword" maxlength="18"/>
      </a-form-item>
      <a-form-item label="新密码确认" name="confirmPassword">
        <a-input-password v-model:value="formData.confirmPassword" maxlength="18"/>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 11, span: 16 }">
        <a-button type="primary" :loading="uploadFormLoading" @click="changePassword">修改</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup>
import { reactive, ref, defineEmits } from 'vue'
import {updatePassword} from "@/api/api";
import {message} from "ant-design-vue";

const passwordForm = ref()
const uploadFormLoading = ref(false)
// 注册一个自定义事件
const emit = defineEmits(['submitPasswordForm'])
const rules = reactive({
  oldPassword: [
    {
      required: true,
      message: '请输入当前使用的密码',
      trigger: 'change'
    },
    {
      required: true,
      message: '请输入当前使用的密码',
      trigger: 'blur'
    },
    {
      min: 6,
      max: 18,
      message: '请输入6到18位你当前使用的密码',
      trigger: 'blur'
    }
  ],
  newPassword: [
    {
      required: true,
      message: '请输入您的新密码',
      trigger: 'change'
    },
    {
      required: true,
      message: '请输入您的新密码',
      trigger: 'blur'
    },
    {
      min: 6,
      max: 18,
      message: '请输入6到18位的新密码',
      trigger: 'blur'
    }
  ],
  confirmPassword: [
    {
      required: true,
      message: '请再输入一遍，确认您的新密码',
      trigger: 'change'
    },
    {
      required: true,
      message: '请再输入一遍，确认您的新密码',
      trigger: 'blur'
    },
    {
      min: 6,
      max: 18,
      message: '请输入6到18位新密码',
      trigger: 'blur'
    }
  ]
})
const formData = reactive({
  oldPassword: '',
  newPassword: '',
  confirmPassword:''
})
const changePassword = () => {
  if(formData.oldPassword === formData.newPassword) {
    message.info('新密码不能与旧密码相同')
    return
  }
  if(formData.newPassword != formData.confirmPassword) {
    message.info('新密码两次输入不一致')
    return
  }
  passwordForm.value.validate().then(
    () => {
      uploadFormLoading.value = true
      updatePassword({...formData}).then(
        res => {
          uploadFormLoading.value = false
          if (res.code === 200) {
            message.success('修改密码成功！')
            emit('submitPasswordForm', true)
          }else{
            message.error(res.msg)
          }
        }
      ).catch(error =>{
        message.error(error.message)
      })
    }
  ).catch(error => {
    message.error('数据验证不通过！！！')
  })
}
</script>

<style scoped lang="less">
.register {
  user-select: none;

  .title {
    text-align: center;
    font-size: 40px;
    color: #1677ff;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
</style>
