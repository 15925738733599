<template>
  <div class="register">
    <div class="title">修改个人信息</div>
    <a-form
      :model="formData.data"
      name="member"
      :label-col="{ span: 6 }"
      ref="memberForm"
      :rules="rules"
      :wrapper-col="{ span: 14 }"
      autocomplete="off"
    >
      <a-form-item label="邮箱" name="email">
        <a-input v-model:value="formData.data.email" maxlength="30" readonly="true"/>
      </a-form-item>
      <a-form-item label="昵称" name="nickName">
        <a-input v-model:value="formData.data.nickName" maxlength="15"/>
      </a-form-item>
      <a-form-item label="手机号" name="mobile" >
        <a-input v-model:value="formData.data.mobile" maxlength="20"/>
      </a-form-item>
      <a-form-item label="个人图片" name="pictureFile" v-if="formData.data.role=='provider'">
        <a-upload
          name="file"
          v-model:file-list="fileList"
          :headers="headers"
          list-type="picture-card"
          class="avatar-uploader"
          action="/api/file/upload"
          :data="{biz:'codeImage'}"
          :show-upload-list="false"
          :before-upload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="formData.data.pictureFile" :src="formData.data.pictureFile" alt="avatar" style="height: 102px; width: 102px;"/>
          <div v-else>
            <loading-outlined v-if="uploadPictureLoading"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 11, span: 16 }">
        <a-button type="primary" :loading="uploadFormLoading" @click="changeMember">修改</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, defineEmits } from 'vue'
import {getMember, updateMember} from "@/api/api";
import {message} from "ant-design-vue";
const memberForm = ref()
const uploadFormLoading = ref(false)
const uploadPictureLoading = ref(false)
const fileList = ref([])
const headers = {
  'member-token': sessionStorage.getItem('member-token')
}
// 注册一个自定义事件
const emit = defineEmits(['submitMemberForm'])
const rules = reactive({
  nickName: [
    {
      required: true,
      message: '请输入用户昵称',
      trigger: 'change'
    },
    {
      required: true,
      message: '请输入用户昵称',
      trigger: 'blur'
    }
  ],
  email: [
    {
      required: true,
      pattern:/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      message: '邮箱格式不正确！',
      trigger: 'blur'
    }
  ],
  mobile: [
    {
      pattern: /^1[3456789]\d{9}$/,
      message: '手机号格式不正确！',
      trigger: 'blur'
    }
  ]
})
 const formData = reactive({
   data:{
     nickName: '',
     email: '',
     mobile: '',
     role: '',
     pictureFile: '',
     id: 0
   }
})
// 上传之前验证是否是图片
const beforeUpload = (file) => {
  // 图片格式限制为 jpeg、png
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('请选择jpg/png图片')
  }
  // 图片大小限制为 2M以内
  const isLt2M = file.size / 1024 / 1024 <= 2
  if (!isLt2M) {
    message.error('图片大小不能超过2MB，请重新上传')
  }
  return isJpgOrPng && isLt2M
}
const handleChange = (info) => {
  if (info.file.status === 'uploading') {
    uploadPictureLoading.value = true
  }
  if (info.file.status === 'done') {
    uploadPictureLoading.value = false
    if (info.file.response.code === 200) {
      formData.data.pictureFile = info.file.response.msg
      fileList.value[0] = info.file.response.msg
    }
  }
  if (info.file.status === 'error') {
    uploadPictureLoading.value = false
    message.error('图片上传失败!' + info.file.response.msg)
  }
}
const changeMember = () => {
  memberForm.value.validate().then(
    () => {
      uploadFormLoading.value = true
      updateMember({...formData.data}).then(
        res => {
          if (res.code === 200) {
            message.success('修改成功！')
            emit('submitMemberForm', true)
          }else{
            message.error(res.msg)
          }
        }
      ).catch(error => {
        message.error(error.message)
      })
      uploadFormLoading.value = false
    }
  ).catch(error => {
    message.error('数据验证不通过！！！')
  })
  //console.log(formData.data)
}
onMounted(()=>{
  queryMember()
})
// 查询当前会员信息
const queryMember = () => {
  getMember({}).then(res => {
    if (res.code === 200) {
      formData.data.nickName = res.data.nickName
      formData.data.email = res.data.email
      formData.data.mobile = res.data.mobile
      formData.data.role =  res.data.role
      formData.data.pictureFile = res.data.pictureFile
      fileList.value[0] = res.data.pictureFile
      formData.data.id = res.data.id
    }else{
      message.error(res.msg)
    }
  }).catch(error =>{
    message.error(error.message)
  })
}

</script>

<style scoped lang="less">
.register {
  user-select: none;

  .title {
    text-align: center;
    font-size: 40px;
    color: #1677ff;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
</style>
