import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import main from '../views/main/main.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: main,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/home')
      },
      {
        path: '/myDownload',
        name: 'myDownload',
        component: () => import('@/views/download/download')
      },
      {
        path: '/myCredit',
        name: 'myCredit',
        component: () => import('@/views/member/credit')
      },
      {
        path: '/myFavorites',
        name: 'myFavorites',
        component: () => import('@/views/member/favorites')
      },
      {
        path: '/my-source',
        name: 'my-source',
        component: () => import('@/views/source/my-source')
      },
      {
        path: '/upload-source',
        name: 'upload-source',
        component: () => import('@/views/source/upload-source')
      },
      {
        path: '/edit-source',
        name: 'edit-source',
        component: () => import('@/views/source/edit-source')
      },
      {
        path: '/source-details',
        name: 'source-details',
        component: () => import('@/views/source/source-details')
      }
    ]
  }
]

const router = createRouter({
  //history: createWebHistory(),
  history: createWebHashHistory(),
  routes
})

// 路由拦截
// const whiteArr = ['/login']
// router.beforeEach((to, from, next) => {
//   // 访问登录页，放行
//   if (whiteArr.includes(to.path)) return next()
//   // 读取本地存储的token
//   const user = JSON.parse(window.sessionStorage.getItem('user') || '0')
//   // 没有token, 强制跳转到登录页
//   if (!user?.token) return next('/login')
//   next()
// })
export default router
