<template>
  <div class="register">
    <div class="title">会员注册</div>
    <a-form
        :model="formData"
        name="register"
        :label-col="{ span: 6 }"
        ref="registerForm"
        :rules="rules"
        :wrapper-col="{ span: 14 }"
        autocomplete="off"
    >
      <a-form-item label="邮箱" name="email">
        <a-input v-model:value="formData.email" maxlength="30"/>
      </a-form-item>
      <a-form-item label="昵称" name="nickName">
        <a-input v-model:value="formData.nickName" maxlength="15"/>
      </a-form-item>
      <!--a-form-item label="登录名" name="loginName" >
        <a-input v-model:value="formData.loginName" maxlength="30"/>
      </a-form-item -->
      <a-form-item label="密码" name="pwd">
        <a-input-password v-model:value="formData.pwd" maxlength="18"/>
      </a-form-item>
      <a-form-item label="密码确认" name="confirmpwd" >
        <a-input-password v-model:value="formData.confirmpwd" maxlength="18"/>
      </a-form-item>
      <a-form-item label="手机号" name="mobile" >
        <a-input v-model:value="formData.mobile" maxlength="20"/>
      </a-form-item>
      <!-- a-form-item label="头像图片" name="loginName">
        <a-input v-model:value="formData.loginName"/>
      </a-form-item -->
      <a-form-item :wrapper-col="{ offset: 11, span: 16 }">
        <a-button type="primary" :loading="uploadFormLoading" @click="registerClick">注册</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup>
import { reactive, ref, defineEmits } from 'vue'
import {addMember} from "@/api/api";
import {message} from "ant-design-vue";

const registerForm = ref()
const uploadFormLoading = ref(false)
// 注册一个自定义事件
const emit = defineEmits(['registerTrue'])
const rules = reactive({
  email: [
    {
      required: true,
      pattern:/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      message: '请输入正确的邮箱！',
      trigger: 'change'
    },
    {
      required: true,
      pattern:/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      message: '请输入正确的邮箱！',
      trigger: 'blur'
    }
  ],
  nickName: [
    {
      required: true,
      message: '请输入用户昵称',
      trigger: 'change'
    },
    {
      required: true,
      message: '请输入用户昵称',
      trigger: 'blur'
    }
  ],
  confirmpwd: [
    {
      required: true,
      message: '请再输入一遍，确认您的密码',
      trigger: 'change'
    },
    {
      required: true,
      message: '请再输入一遍，确认您的密码',
      trigger: 'blur'
    },
    {
      min: 6,
      max: 18,
      message: '请输入6到18位的密码',
      trigger: 'blur'
    }
  ],
  pwd: [
    {
      required: true,
      message: '请输入密码',
      trigger: 'change'
    },
    {
      required: true,
      message: '请输入密码',
      trigger: 'blur'
    },
    {
      min: 6,
      max: 16,
      message: '请输入6到18位的密码',
      trigger: 'blur'
    }
  ],
  mobile: [
    {
      pattern: /^1[3456789]\d{9}$/,
      message: '手机号格式不正确！',
      trigger: 'change'
    },
    {
      pattern: /^1[3456789]\d{9}$/,
      message: '手机号格式不正确！',
      trigger: 'blur'
    }
  ]
})
const formData = reactive({
  email: '',
  nickName: '',
  pwd: '',
  confirmpwd: '',
  mobile: ''
})
const registerClick = () => {
  registerForm.value.validate().then(
    () => {
      uploadFormLoading.value = true
      addMember({...formData}).then(
        res => {
          uploadFormLoading.value = false
          if (res.code === 200) {
            message.success(res.msg)
            emit('registerTrue', true)
          }else{
            message.error(res.msg)
          }
        }
      ).catch(error => {
        message.error(error.message)
      })
    }
  ).catch(error => {
    message.error('数据验证不通过！！！')
  })
}
</script>

<style scoped lang="less">
.register {
  user-select: none;

  .title {
    text-align: center;
    font-size: 40px;
    color: #1677ff;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
</style>
