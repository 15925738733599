import service from '@/utils/axios'

// 登录
export const loginUser = (params) => {
  return service({
    url: '/api/member/login',
    data: params,
    method: 'post'
  })
}
// 发送登录邮箱验证码
export const sendMailCode = (params) => {
  return service({
    url: '/api/member/mailCode',
    data: params,
    method: 'post'
  })
}
// 发送重置邮件
export const sendResetMail = (params) => {
  return service({
    url: '/api/member/mailReset',
    data: params,
    method: 'post'
  })
}
// 验证码登录
export const codeLogin = (params) => {
  return service({
    url: '/api/member/codeLogin',
    data: params,
    method: 'post'
  })
}
// 退出登录
export const loginOutT = (params) => {
  return service({
    url: '/api/member/logout',
    data: params,
    method: 'get'
  })
}
// 会员注册
export const addMember = (params) => {
  return service({
    url: '/api/member/add',
    data: params,
    method: 'post'
  })
}
//修改会员信息
export const updateMember = (params) => {
  return service({
    url: '/api/member/update',
    data: params,
    method: 'post'
  })
}
//修改会员密码
export const updatePassword = (params) => {
  return service({
    url: '/api/member/modifyPassword',
    data: params,
    method: 'post'
  })
}
//我的下载
export const myDownload = (params) => {
  return service({
    url: '/api/member/myDownload',
    data: params,
    method: 'post'
  })
}
//我的收藏
export const myFavorites = (params) => {
  return service({
    url: '/api/member/myFavorites',
    data: params,
    method: 'post'
  })
}
//我的积分
export const myCredit = (params) => {
  return service({
    url: '/api/member/myCredit',
    data: params,
    method: 'post'
  })
}
//查询会员信息
export const getMember = (params) => {
  return service({
    url: '/api/member/id',
    params: params,
    method: 'get'
  })
}
// 获取验证码
export const getCode = (params) => {
  return service({
    url: '/api/captcha/login',
    params: params,
    responseType: 'blob',
    method: 'get'
  })
}
// 获取首页List
export const homeList = (params) => {
  return service({
    url: '/api/resource/list',
    params: params,
    method: 'get'
  })
}
// 首页total
export const homeCount = (params) => {
  return service({
    url: '/api/resource/count',
    params: params,
    method: 'get'
  })
}
// 我的资源列表
export const myResourceList = (params) => {
  return service({
    url: '/api/resource/myList',
    params: params,
    method: 'get'
  })
}
// 我的资源列表总数
export const myResourceCount = (params) => {
  return service({
    url: '/api/resource/myCount',
    params: params,
    method: 'get'
  })
}
// 通过id查询详情
export const sourceDetails = (params) => {
  return service({
    url: '/api/resource/id',
    params: params,
    method: 'get'
  })
}
// 查询自己的资源，用于编辑回显
export const queryMyResource = (params) => {
  return service({
    url: '/api/resource/myPart',
    params: params,
    method: 'get'
  })
}
// 上传资源相关
export const uploadImg = (params) => {
  return service({
    url: '/api/file/upload',
    data: params,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq',
      'member-token': localStorage.getItem('member-token')
    }
  })
}
//下载资源
export const downloadFile = (partId) => {
  return service({
    url: '/api/file/download?id='+partId,
    method: 'get',
    headers: {
      'member-token': localStorage.getItem('member-token')
    }
  })
}



export const addDetails = (params) => {
  return service({
    url: '/api/resource/add',
    data: params,
    method: 'post'
  })
}
//编辑资源
export const editResource = (params) => {
  return service({
    url: '/api/resource/update',
    data: params,
    method: 'post'
  })
}
//添加收藏
export const addFavorite = (params) => {
  return service({
    url: '/api/member/addFavorite',
    data: params,
    method: 'post'
  })
}
//移除收藏
export const removeFavorite = (params) => {
  return service({
    url: '/api/member/removeFavorite',
    data: params,
    method: 'post'
  })
}
//调用统一下单API，生成支付二维码
export const getWxPayCode = (partId) => {
  return service({
    url: '/api/api/wx-pay/native/'+partId,
    data: '',
    method: 'post'
  })
}
//查询订单状态
export const getOrderStatus = (orderId) => {
    return service({
      url: '/api/order/status/' + orderId,
      data: '',
      method: 'get'
    })
}
//用积分支付
export const payWithCredit = (partId) => {
  return service({
    url: '/api/order/credit/'+partId,
    data: '',
    method: 'post'
  })
}


