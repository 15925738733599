import axios from 'axios'
import { message as Message } from 'ant-design-vue'
import router from '../router/index'
import { loginOutT } from '@/api/api'
// 创建一个 axios 实例
const service = axios.create({
  baseURL: '', // 所有的请求地址前缀部分
  timeout: 600000, // 请求超时时间毫秒
  withCredentials: true, // 异步请求携带cookie
  transformResponse: [
    function (data) {
      // 对 data 进行任意转换处理
      return data
    }
  ]
})

// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json;charset=utf-8'
    }
    const token = sessionStorage.getItem('member-token') ? sessionStorage.getItem('member-token') : null
    if (token) {
      config.headers['member-token'] = token // 设置请求头
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

// 添加响应拦截器
service.interceptors.response.use(
  (res) => {
    const contentDisposition = res.headers.get('content-disposition')
    if (contentDisposition && contentDisposition.indexOf('fileName=') !== -1) {
      return res
    }
    let data
    if (typeof res.data === 'string') {
      data = JSON.parse(res.data)
    } else {
      data = res.data
    }
    // 统一处理下后端返回参数
    if (data.code === 200) {
    } else if (data.code === 401) {
      // router.replace({ name: 'login' }) //屏蔽不用去跳转
      //Message.error('登录过期，请重新登陆！')
      sessionStorage.clear()
    } else {
    }
    return data
  },
  (err) => {
    console.log('axios err:'+err)
    return Promise.reject(err)
  }
)

export default service
