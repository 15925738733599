<template>
  <div class="nav" ref="nav">
    <div class="left">
      <div @click="turnTo('/home')">
        <img src="../../assets/logo.png" alt="" width="20" height="20">
        <span class="title">优思资源网</span>
      </div>
    </div>
    <div class="right">
      <ul v-if="userShow">
        <li v-if="userInfo.role == 'provider'" @click="turnTo('/upload-source')">上传资源</li>
        <li v-if="userInfo.role == 'provider'" @click="turnTo('/my-source')">我的资源</li>
        <li @click="turnTo('/myFavorites')">我的收藏</li>
        <li @click="turnTo('/myDownload')">我的下载</li>
        <li v-if="userInfo.role == 'provider'" @click="turnTo('/myCredit')">我的积分</li>
        <li>
          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              <a-avatar v-if="userInfo.imageUrl" :src="userInfo.imageUrl"/>
              <a-avatar v-else style="background-color: #1890ff">
                <template #icon>
                  <UserOutlined/>
                </template>
              </a-avatar>
              <span style="vertical-align: middle;margin-left: 10px" >    {{ userInfo.nickName }}</span>
              <!--              <DownOutlined />-->
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a href="javascript:;" @click="openMemberModal('member')">个人信息</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="openMemberModal('password')">修改密码</a>
                </a-menu-item>
                <a-menu-item>
                  <a href="javascript:;" @click="loginOut">退出</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </li>
      </ul>
      <ul v-if="!userShow">
        <li @click="openModal(1)">登录</li>
        <li @click="openModal(2)">注册</li>
      </ul>
    </div>
    <a-modal
        v-model:open="visible"
        title=""
        wrapClassName="modalClass"
        :getContainer="() => $refs.nav"
        @cancel="closeModal"
        :footer="null">
      <div class="content">
        <login v-if="loginOrRegister" @loginTrue="closeModal"></login>
        <register v-if="!loginOrRegister" @registerTrue="closeModal"></register>
      </div>
    </a-modal>
    <a-modal
      v-model:open="izMemberModal"
      title=""
      wrapClassName="modalClass"
      :getContainer="() => $refs.nav"
      @cancel="closeMemberModal"
      :footer="null">
      <div class="content">
        <changeMember v-if="izMemberForm" @submitMemberForm="closeMemberModal"></changeMember>
        <changePassword v-if="izPasswordForm" @submitPasswordForm="closeMemberModal"></changePassword>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import login from '@/views/login/login'
import register from '@/views/login/register'
import changeMember from '@/views/member/changeMember'
import changePassword from '@/views/member/changePassword'

import { useRouter } from 'vue-router'
import {onMounted, onBeforeMount, reactive, ref, createVNode, nextTick} from 'vue'
import { loginOutT,getMember } from '@/api/api'
import { message, Modal } from 'ant-design-vue'
import { UserOutlined } from '@ant-design/icons-vue'

const userShow = ref(false)
const router = useRouter()
const turnTo = (path) => {
  router.push(path)
}
const visible = ref(false)
const izMemberModal = ref(false)
const izMemberForm = ref(false)
const izPasswordForm = ref(false)

const nav = ref()
const loginOrRegister = ref(true)
const userInfo = reactive({
  imageUrl: '',
  nickName: '',
  role: ''
})
const openModal = (val) => {
  if (val === 1) {
    // 登录
    loginOrRegister.value = true
  } else {
    // 注册
    loginOrRegister.value = false
  }
  visible.value = true
}
const openMemberModal = (val) => {
  //检查会员在服务器端的登录，是否已经过期。
  getMember({}).then(res => {
    if(res.code === 200){
      // 登录
      if(val === 'member'){
        izMemberModal.value = true
        izMemberForm.value = true
        izPasswordForm.value = false
      }else if(val === 'password'){
        izMemberModal.value = true
        izPasswordForm.value = true
        izMemberForm.value = false
      }else{
        console.log('openMemberModal val error:'+val)
      }
    }else if(res.code === 401){
      userShow.value = false
      userInfo.nickName = ''
      userInfo.imageUrl = ''
      userInfo.role = ''
    }else{
      message.error(res.msg)
    }
  }).catch(error =>{
    message.error(error.message)
  })
}
const closeMemberModal = () => {
  izMemberModal.value = false
  izPasswordForm.value = false
  izMemberForm.value = false
}

// 退出登录
const closeModal = () => {
  visible.value = false
  loginOrRegister.value = !loginOrRegister.value
}
// 退出登录
const loginOut = () => {
  Modal.confirm({
    title: '提示?',
    okText: '确认',
    cancelText: '取消',
    content: createVNode('div', {}, '您确定要退出当前账号吗?'),
    onOk () {
      // 这儿清除缓存
      loginOutT().then(res => {
        if (res.code === 200) {
          sessionStorage.clear()
          userShow.value = false
          userInfo.nickName = ''
          userInfo.imageUrl = ''
          userInfo.role = ''
          message.success('用户已退出登录')
          nextTick(() => {
            window.location.reload()
          })
        }else{
          message.error(res.msg)
        }
      }).catch(error =>{
        message.error(error.message)
      })
    },
    onCancel () {
      console.log('Cancel')
    },
    class: ''
  })
}

onMounted(() => {
  // 初始化去查看是否有token缓存
  let token = sessionStorage.getItem('member-token')
  let user = JSON.parse(sessionStorage.getItem('memberInfo'))
  if (token) {
    userShow.value = true
    userInfo.nickName = user.nickName
    userInfo.imageUrl = user.imageUrl
    userInfo.role = user.role
  }else{
    userShow.value = false
    userInfo.nickName = ''
    userInfo.imageUrl = ''
    userInfo.role = ''
  }
})
// 未登录时，去登录后的监听事件
window.addEventListener('setItem', () => {
  //console.log('EventListener from headerBox.vue.')
  let token = sessionStorage.getItem('member-token')
  let user = JSON.parse(sessionStorage.getItem('memberInfo'))
  if (token) {
    userShow.value = true
    userInfo.nickName = user.nickName
    userInfo.imageUrl = user.imageUrl
    userInfo.role = user.role
  } else {
    userShow.value = false
    userInfo.nickName = ''
    userInfo.imageUrl = ''
    userInfo.role = ''
  }
})

</script>

<style scoped lang="less">
.nav {
  height: 50px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;

  .left {
    width: 300px;
    text-align: left;
    cursor: pointer;

    .title {
      margin-left: 20px;
    }
  }

  .right {
    width: calc(100% - 300px);
    height: 100%;
    text-align: right;

    ul {
      width: auto;
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: right;

      li {
        user-select: none;
        padding: 0 20px;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }

  .modalClass {
    .content {
      text-align: left;
      //
    }
  }
}
</style>
