<template>
  <div class="login">
    <div class="title">登录</div>
    <a-tabs v-model:activeKey="activeKey" :centered="true">
      <a-tab-pane key="1" tab="账号登录">
        <a-form
            :model="formData"
            name="login"
            :label-col="{ span: 6 }"
            ref="loginForm"
            :rules="rules"
            :wrapper-col="{ span: 14 }"
            autocomplete="off">
          <a-form-item label="邮箱" name="email">
            <a-input v-model:value="formData.email" placeholder="输入登录邮箱"/>
          </a-form-item>
          <a-form-item label="密码" name="password">
            <a-input-password v-model:value="formData.password" style="width: 150px"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a-button style="background-color: darkgrey" :loading="resetLoading" @click="sendMailForReset">重置</a-button>
          </a-form-item>
          <a-form-item label="验证码" name="verifyCode">
            <a-input v-model:value="formData.verifyCode" style="width: 150px"/>
            <img class="verifyCode" :src="verifyCodeUrl" alt="" width="100" height="30" @click="getCodeUrl">
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 11, span: 16 }">
            <a-button type="primary" @click="loginClick" :loading="loginLoading">登录</a-button>
          </a-form-item>
          <!--a-form-item :wrapper-col="{ offset: 2, span: 25 }">
            <a-radio-group v-model:value="isCharge">
              <a-radio value="1"></a-radio>
            </a-radio-group>
            登录即代表我已阅读并同意 <span><a href="./agreement.html" target="_blank">《用户协议》</a></span>
          </a-form-item -->
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="验证码登录">
        <a-form
          :model="codeData"
          name="codeLogin"
          :label-col="{ span: 6 }"
          ref="codeForm"
          :rules="rules1"
          :wrapper-col="{ span: 14 }"
          autocomplete="off">
          <a-form-item label="邮箱" name="mailBox">
            <a-input v-model:value="codeData.mailBox" placeholder="输入登录邮箱"/>
          </a-form-item>
          <a-form-item label="邮箱验证码" name="passCode">
            <a-input v-model:value="codeData.passCode" style="width: 150px"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a-button style="background-color: darkgrey" :loading="codeLoading" @click="sendMailForCode">发送</a-button>
          </a-form-item>
          <a-form-item label="验证码" name="pictureCode">
            <a-input v-model:value="codeData.pictureCode" style="width: 150px"/>
            <img class="verifyCode" :src="verifyCodeUrl" alt="" width="100" height="30" @click="getCodeUrl">
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 11, span: 16 }">
            <a-button type="primary" @click="codeLoginClick" :loading="codeLoginLoading">登录</a-button>
          </a-form-item>
          <!-- a-form-item :wrapper-col="{ offset: 2, span: 25 }">
            <a-radio-group v-model:value="isCharge">
              <a-radio value="1"></a-radio>
            </a-radio-group>
            登录即代表我已阅读并同意 <span><a href="./agreement.html" target="_blank">《用户协议》</a></span>
          </a-form-item -->
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="3" tab="微信登录">
        <div class="wxCode">
          <img :src="wxUrl" alt="" width="250">
          <p class="tip">开发中，敬请等待！</p>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, getCurrentInstance, defineEmits } from 'vue'
import { getCode, loginUser,sendMailCode,codeLogin,sendResetMail } from '@/api/api'
import { message } from 'ant-design-vue'

// 注册一个自定义事件
const emit = defineEmits(['loginTrue'])
const loginForm = ref()
const codeForm = ref()
const activeKey = ref('1')
const rules = reactive({
  email: [
    {
      required: true,
      pattern:/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      message: '请输入正确的邮箱！',
      trigger: 'change'
    },
    {
      required: true,
      pattern:/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      message: '请输入正确的邮箱！',
      trigger: 'blur'
    }
  ],
  verifyCode: [
    {
      required: true,
      message: '请输入验证码',
      trigger: 'change'
    },
    {
      required: true,
      message: '请输入验证码',
      trigger: 'blur'
    }
  ],
  password: [
    {
      required: true,
      message: '请输入密码',
      trigger: 'change'
    },
    {
      required: true,
      message: '请输入密码',
      trigger: 'blur'
    },
    {
      min: 6,
      max: 12,
      message: '请输入6到12位的密码',
      trigger: 'blur'
    }
  ]
})
const formData = reactive({
  email: '',
  password: '',
  verifyCode: ''
})

const rules1 = reactive({
  mailBox: [
    {
      required: true,
      pattern:/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      message: '请输入正确的邮箱地址！',
      trigger: 'change'
    },
    {
      required: true,
      pattern:/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
      message: '请输入正确的邮箱地址！',
      trigger: 'blur'
    }
  ]
})
const codeData = reactive({
  mailBox: '',
  passCode: '',
  pictureCode: ''
})
const isCharge = ref('')
const loginLoading = ref(false)
const codeLoginLoading = ref(false)
const codeLoading = ref(false)
const resetLoading = ref(false)
// 这是图片验证码
const verifyCodeUrl = ref('')
const { proxy } = getCurrentInstance()
const loginClick = () => {
  // if (isCharge.value === '') {
  //   console.log(isCharge.value === '')
  //   message.error('请先勾选我阅读并同意')
  //   return
  // }
  loginForm.value.validate().then(() => {
    loginLoading.value = true
    loginUser({ ...formData }).then(res => {
      if (res.code === 200) {
        sessionStorage.setItem('memberInfo', JSON.stringify({
          imageUrl: res.data.imageUrl,
          nickName: res.data.nickName,
          role: res.data.role
        }))
        proxy.$addStorageEvent(2, 'member-token', res.data.token)
        emit('loginTrue', true)
      } else {
        message.error(res.msg)
      }
    }).catch(error =>{
      message.error(error.message)
    })
    loginLoading.value = false
  }).catch(error => {
    message.error('数据验证不通过！！！')
  })
}
const sendMailForCode = () => {
  codeLoading.value = true;
  codeForm.value.validate().then(() => {
    sendMailCode({ ...codeData }).then(res => {
      if (res.code === 200) {
        message.info(res.msg)
      }else {
        message.error(res.msg)
      }
    }).catch(error =>{
      message.error(error.message)
    })
  }).catch(error => {
    message.error('邮箱格式不正确！！！')
  })
  codeLoading.value = false;
}
const sendMailForReset = () => {
  const emailRegex = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
  if (!emailRegex.test(formData.email)) {
    message.error('邮箱格式不正确！！！')
    return
  }//sendResetMail
  resetLoading.value = true;
  sendResetMail({ ...formData }).then(res => {
    if (res.code === 200) {
      message.info(res.msg)
    }else {
      message.error(res.msg)
    }
  }).catch(error =>{
    message.error(error.message)
  })
  resetLoading.value = false;
}

const codeLoginClick = () => {
  // if (isCharge.value === '') {
  //   console.log(isCharge.value === '')
  //   message.error('请先勾选我阅读并同意')
  //   return
  // }
  codeForm.value.validate().then(() => {
    codeLoginLoading.value = true
    codeLogin({ ...codeData }).then(res => {
      if (res.code === 200) {
        sessionStorage.setItem('memberInfo', JSON.stringify({
          imageUrl: res.data.imageUrl,
          nickName: res.data.nickName,
          role: res.data.role
        }))
        proxy.$addStorageEvent(2, 'member-token', res.data.token)
        emit('loginTrue', true)
      } else {
        message.error(res.msg)
      }
    }).catch(error =>{
      message.error(error.message)
    })
    codeLoginLoading.value = false
  }).catch(error => {
    message.error('数据验证不通过！！！')
  })
}

const getCodeUrl = () => {
  getCode().then(res => {
    const blobURL = window.URL.createObjectURL(res)
    verifyCodeUrl.value = blobURL
  })
}
// 这是切换微信登录页 默认用的一张截图，通过调接口切换
//const wxUrl = ref(require('../../assets/codeWx.jpg'))
onMounted(() => {
  getCodeUrl()
})

</script>

<style scoped lang="less">
.login {
  user-select: none;

  .title {
    text-align: center;
    font-size: 40px;
    color: #1677ff;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .verifyCode {
    float: right;
    cursor: pointer;
  }

  /deep/ .wxCode {
    height: 280px;
    text-align: center;

    .tip {
      margin-top: 30px;
    }
  }

  .code {
    cursor: pointer;
  }
}
</style>
